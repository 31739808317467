import React, { useState, useEffect } from 'react'
import API from './utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import Countdown from 'react-countdown';
import ReactSession from '../ReactSession';
import LinesEllipsis from 'react-lines-ellipsis';


const OutletSection = ({token, currentUser, currentCompany, context}) => {
  const [outlets, setOutlets] = useState([]);

  const loadData = () => {
    let config = { headers: { Authorization: `Bearer ${token}`}, params: {paginate: false, by_status: true} };

    API.get(`outlets`, config)
      .then(res => {
        const data = res.data.data;
        setOutlets(data)
      })
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {
        currentUser && currentCompany && (currentCompany.last_package && (currentCompany.last_package.can_multi_outlet == true)) && outlets.length > 0 ? (
          <>
            {
              context && context === 'dashboard' ? (
                <div className="row p-top-10 margin-none">
                  <div className="col s12 m12">
                    <span className='title-card-content'>Buat Pesanan Sekarang</span>
                  </div>
                </div>
              ) : null
            }
            
            <div className="row margin-none back-grey">
              <div className="col s12 m12">
                <div class="full-outlet-box">
                  <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>domain</i>  
                  {
                    currentUser.outlet && currentUser.outlet.name ? (
                      <>
                        <span className='font-box'>
                          &nbsp; {currentUser?.outlet?.name}
                        </span>
                        <Link to={'/outlets'} className="btn-box">Pilih Outlet</Link>
                        <div className='box-address' style={{fontWeight: 400}}>
                          <span style={{color: 'green'}}>Jam Buka: {currentUser?.outlet?.clock_start} s/d {currentUser?.outlet?.clock_end} WIB </span>
                          <br/>
                          {currentUser?.outlet?.address}, {currentUser?.outlet?.district}, {currentUser?.outlet?.city}, {currentUser?.outlet?.province}
                        </div>
                      </>
                    ) : (
                      <>
                        <span className='font-box'>
                          &nbsp; Belum ditentukan
                        </span>
                        <Link to={'/outlets'} className="btn-box">Pilih Outlet</Link>
                        <div className='box-address' style={{fontWeight: 400}}>
                          Lokasi pembelian outlet belum ditentukan
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </>
        ) : null
      }
    </>
  )
}

export default OutletSection
