import React, { useEffect, useState, useParams } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import HeaderScreen from '../components/HeaderScreen'
import ScreenLoading from '../components/ScreenLoading'
import M from 'materialize-css';
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import BottomSheet from '../components/BottomSheet';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const EditProfile = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const alert = useAlert();
  const history = useHistory();
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [data, setData] = useState({});
  const [report, setReport] = useState({});


  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status_payment: 'paid'} };

    API.get(`report_user`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      })

    API.get(`sessions`, config )
      .then(res => {
        setSpinnerScreen(false);
        const data = res.data.data;
        setData(data);
        let elems = document.getElementById("sel");
        let instances = window.M.FormSelect.init(elems, {});
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }


  useEffect(() => {
    if(token === undefined){
      history.push("/login")
    }else{
      loadData();

      let elSide = document.querySelectorAll('.sidenav-overlay');
      let elBody = document.querySelectorAll('body');
      elSide.forEach((item: any) => {
        item.style.display = 'none';
      })
      elBody.forEach((item: any) => {
        item.style.overflow = 'auto';
      })

      document.addEventListener('DOMContentLoaded', function() {
        let elems = document.querySelectorAll('.datepicker');
        let instances = M.Datepicker.init(elems);
      });

      let elems = document.getElementById("sel");
      let instances = window.M.FormSelect.init(elems, {});
    }

  }, []);

  return (
    currentCompany ? (
    <>
      <HeaderScreen title="Pengaturan Profil"/>
      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div className="row bg-grey m-bottom0 box-profile-info">
            <div className='col col s12'>
              <div className='avatar'>
                <img src={data.avatar_url}/>
              </div>
            </div>
            <div className='col col s12 m12'>
              <center>
                <div className='full-name'><b>{data.full_name}</b></div>
                <div>
                  Akun {data.group_user_name ? data.group_user_name : 'Customer'}
                </div>
              </center>
            </div>
          </div>

          {
            (currentCompany.last_package && currentCompany.last_package.can_group_user == true ) && (data.group_user_name === null || data.group_user_name === 'Customer') && (currentCompany.config_features && currentCompany.config_features['use_upgrade_user']) && !currentCompany.cannot_group_user ? (
              <div className="row border-bottom">
                <div className="col s12 m12">
                  <div className='box-bank-number' style={{textAlign: 'left', background: 'white', margin: 0}}> 
                    <small>
                      Untuk <b>Upgrade Ke Akun Mitra</b> Yang Resmi Anda Harus Melakukan Pembelian Awal Sample Produk Terlebih Dahulu Lewat Admin, Sehingga Nantinya Anda Akan Dapat Harga Member Yang Jauh Lebih Murah Dari Harga Customer Tentunya. Untuk Upgrade Ke Akun Mitra Resmi Silahkan Hub Admin Dengan Klik Tombol Button Upgrade Akun Dibawah Detail Akun.
                    </small>
                    <br/>
                    <div>
                      <br/>
                      <a href={'https://api.whatsapp.com/send?phone='+currentCompany.phone1+'&text=Saya%20mau%20upgrade%20akun%20mitra%20dengan%20id%20'+data.code+'%20Nama%20'+data.full_name} className='btn' style={{width: '100%', background: (currentCompany.company_color2)}}>Upgrade Akun Mitra</a>
                      <br/>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }

          { (currentCompany.last_package && currentCompany.last_package.can_referral == true ) && data.affiliate === true ? (
            <div className="row bg-grey m-bottom0">
              <div className="col s12 pb-10">
                <div className="profile-info-table space-mb--40 reff">
                  <div className="profile-info-block">
                    <div className="profile-info-block__title">Kode Referral Anda</div>
                    <div className="profile-info-block__value"><b style={{fontSize: 15}}>{data.code}</b></div>
                  </div>
                </div>
              </div>
            </div>
          ) : null }

          <div className="row bg-grey m-bottom0">
            <div className="col s12 pb-10">
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Jumlah Transaksi</div>
                  <div className="profile-info-block__value"><b>{report.order_store ? currencyFormat(report.order_store) : numberFormat(0)}</b></div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Jumlah Pesanan</div>
                  <div className="profile-info-block__value"><b>{report.order_count ? numberFormat(report.order_count) : numberFormat(0)}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bg-grey m-bottom0">
            <div className="col s12 pb-10">
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Ubah Kata Sandi</div>
                  <div className="profile-info-block__value">
                    <Link to="/edit_password" ><i className="material-icons" style={{color: (currentCompany.company_color1)}}>settings</i></Link>  
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bg-grey m-bottom0">
            <div className="col s12 pb-10">
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Ubah Profil</div>
                  <div className="profile-info-block__value">
                    <Link to="/edit_profile" ><i className="material-icons" style={{color: (currentCompany.company_color1)}}>settings</i></Link>  
                  </div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Nama Lengkap</div>
                  <div className="profile-info-block__value">{data.full_name}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Email</div>
                  <div className="profile-info-block__value">{data.email}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Bergabung Sejak</div>
                  <div className="profile-info-block__value">{data.created_at}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">No.HP/Whatsapp</div>
                  <div className="profile-info-block__value">{data.phone1 ? data.phone1 : null}</div>
                </div>
              </div>
              {/* <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Kontak 2</div>
                  <div className="profile-info-block__value">{data.phone2 ? data.phone2 : null}</div>
                </div>
              </div> */}
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Jenis Kelamin</div>
                  <div className="profile-info-block__value">{data.gender}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Tanggal Lahir</div>
                  <div className="profile-info-block__value">{data.birthday}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Alamat Domisili</div>
                  {
                    data.district ? (
                      <div className="profile-info-block__value">{data.district}, {data.city}, {data.province}</div>
                    ) : (
                      <div className="profile-info-block__value">Belum Ditentukan</div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>

          {
            (currentCompany.last_package && currentCompany.last_package.can_virtual_money == true ) ? (
              <div className="row bg-grey m-bottom0">
                <div className="col s12">
                  <div className="profile-info-table space-mb--40">
                    <div className="profile-info-block">
                      <div className="profile-info-block__title">No Rekening Reguler</div>
                      <div className="profile-info-block__value">
                        <Link to="/edit_payment" ><i className="material-icons" style={{color: (currentCompany.company_color1)}}>settings</i></Link>  
                      </div>
                    </div>
                  </div>
                  <div className="profile-info-table space-mb--40">
                    <div className="profile-info-block">
                      <div className="profile-info-block__title">Nama Bank</div>
                      <div className="profile-info-block__value">{data.payment_bank_name ? data.payment_bank_name : 'Belum di Isi'}</div>
                    </div>
                  </div>
                  <div className="profile-info-table space-mb--40">
                    <div className="profile-info-block">
                      <div className="profile-info-block__title">No Rekening</div>
                      <div className="profile-info-block__value">{data.payment_bank_number ? data.payment_bank_number : 'Belum di Isi'}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }

          <BottomSheet/>
        </>
      )}
    </>
    ) : null
  )
}

export default EditProfile