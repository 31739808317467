import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import BottomSheet from '../components/BottomSheet';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const HistoryPoints = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'company', category: 'point'  }};
    API.get(`activity_virtuals`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', include: 'company', category: 'point' } };
    
    API.get(`activity_virtuals`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    loadData(search);
  }

  useEffect(() => {  
    if(token === undefined){
      history.push("/login")
    }

    loadData();
    setSpinnerScreen(false);  

  },[])

  return (
    currentCompany ? (
    <>
      <HeaderScreen title="Riwayat Poin" link="/dashboard"/>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div class='row m-bottom0'>
            <div className="col s12">
              <div className="input-field search-on-list m-top0">
                <input className="input-back" autocomplete="off" type="search" value={search} onChange={e => updateSearch(e.target.value)}/>
                <label className="label-icon" for="search"><i className="material-icons material-icon-search" style={{color: (currentCompany.company_color1)}}>search</i></label>
              </div>
              <>
                { spinner ? (
                  <div className='text-center'>
                    <div className='loadLoading'><Dots color='#444' /></div>
                  </div>
                ) : (
                  <>
                    { data && data.length > 0 ? (
                      <>
                        {
                          data.map((u) => {
                            return (
                              <div className='list-content' key={u.id}>
                                <div class='row m-bottom0 row-netral'>
                                  <div className="col s2">
                                    <div className='acumulation-input'>
                                      <i className="material-icons" style={{color: (currentCompany.company_color1)}}>stars</i>
                                    </div>
                                  </div>
                                  <div className="col s7">
                                    {u.title}
                                    <br/>
                                    {u.created_at}
                                  </div>
                                  <div className="col s3" style={{textAlign: 'right'}}>
                                    <b style={{fontSize: 12}}>{u.status === 'in' || u.status === 'on' ? '+' : '-'}{numberFormat(u.point)} POIN</b>  
                                    <br/>
                                    <span style={styles.status_payment(u.status)}>{u.status === 'in' || u.status === 'on' ? 'Poin Masuk' : 'Poin Keluar'}</span>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        </>
                      ) : (
                        <div className='col s12 m12'>
                          <div className='blank-message' style={{padding: 20}}>
                            <i class="material-icons dp48" style={{fontSize:50, marginBottom: 10}}>filter_none</i>
                            <br/>
                            Data Tidak Ditemukan
                          </div>
                        </div>
                      )
                    }
                  </>
                )}
                {paging ? (
                  <div onClick={loadMoreData} class='load-more-btn-white'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                ) : null}
              </>
            </div>
          </div>

        </>
      )}
      <BottomSheet/>
    </>
    ) : null
  );
}

export default HistoryPoints;

const styles: StyleSheet = {
  status_payment: (status) => ({
    fontSize: 11,
    color: status === 'in' || status === 'on' ? 'green' : 'red' ,
    paddingBottom: 2,
    borderRadius: 0,
    fontWeight: 400,
    position: 'absolute',
    right: 10
  })
}