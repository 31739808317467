import React, { useEffect, useState } from 'react';
import ReactSession from '../ReactSession';
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import API from '../components/utils/api';
import M from 'materialize-css';

const Header = ({keywords}) => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const current_order_count = ReactSession.get("current_order_count");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();
  const [search, setSearch] = useState(keywords);
  const [collection, setCollection] = useState([]);

  const onClickSearch = keyword => {
    setSearch(keyword)
    setCollection([])
    if(window.location.href.includes('/products')){
      window.location.replace(`/products?key=${keyword}`)
    }else{
      history.push(`/products?key=${keyword}`);
    }
    
  }

  const onClickSearchClose = () => {
    setSearch('')
    setCollection([])
    if(window.location.href.includes('/products')){
      window.location.replace(`/products`)
    } 
  }

  const handleSearch = event => {
    setSearch(event.target.value);
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: event.target.value, by_status: true, per_page: 6} };
    if(event.target.value.length > 2){
      API.get(`products`, config)
        .then(res => {
          const data = res.data.data;
          setCollection(data)
        })
    }else{
      setCollection([])
    }
  };

  const actLogout = () => {
    const token = ReactSession.get("token");
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.delete('sessions', config )
      .then(res => {
        ReactSession.remove("token");
        ReactSession.remove("current_user");
        history.push("/login");

        let elements = document.querySelectorAll('.sidenav-overlay');
        elements.forEach((item: any) => {
          item.style.display = 'none';
        })
      })
      .catch(error => {
        ReactSession.remove('token')
        ReactSession.remove('current_user')
        history.push("/login");
        console.log(error.response);
      })
  }

  const [stickyClass, setStickyClass] = useState('relative');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('nav-scroll') : setStickyClass('');
    }
  };
  

  useEffect(() => {
    M.AutoInit();
    window.addEventListener('scroll', stickNavbar);
    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
    
  },[])

  return (
    currentCompany ? (
      <>
        <div className="navbar-fixed">
          <nav className={`${stickyClass}`} style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
            <div className="nav-wrapper">
              {/* <Link to="/dashboard" className="brand-logo">
                <img src={currentCompany.company_logo_url}/>
              </Link>   */}
              {/* <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a> */}
              <ul className="left" style={{width: '70%'}}>
                <li style={{width: '100%'}}>
                  <div className="input-field full-width">
                    <input id="search" autocomplete="off" type="search" value={search} onChange={handleSearch} onKeyPress={event => {
                        if (event.key === 'Enter') {
                          onClickSearch(event.target.value)
                        }
                      }}
                    />
                    <label className="label-icon" for="search"><i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color1 : '' ) }}>search</i></label>
                    { search && search.length > 0 ? (<i className="material-icons" onClick={onClickSearchClose}>close</i>) : null}
                    { collection && collection.length > 0 ? (
                      <>
                        <ul className='search-field'>
                          {collection.map(item => (
                            <li key={item.id} onClick={e => onClickSearch(item.title)}>
                              {item.title}
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : null}
                  </div>
                </li>
              </ul>
              <ul className="right" style={{width: '30%'}}>
                { currentUser ? (
                  <li style={{float: 'right'}}>
                    <a href="#" data-target="mobile-demo" className="sidenav-trigger">
                      <i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>format_align_right</i> 
                    </a>
                  </li>
                ) : (
                  <li style={{float: 'right'}}>
                    <Link to="/login">
                      <i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>format_align_right</i> 
                    </Link>
                  </li>
                ) }
                <li style={{float: 'right'}}>
                  { currentUser ? (
                    <Link to="/carts">
                      <i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>shopping_basket</i>
                      { current_order_count ? ( <span className='icon-shop-count'>{current_order_count}</span> ) : null }
                    </Link>
                  ) : (
                    <Link to="/login">
                      <i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>shopping_basket</i>
                      { current_order_count ? ( <span className='icon-shop-count'>{current_order_count}</span> ) : null }
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <ul className="sidenav" id="mobile-demo" style={{background: (currentCompany.company_color2)}}>
          { currentUser ? (
            <li className='sidenav-profile'>
              <div className='avatar'>
                <img src={currentUser.avatar_url}/>
              </div>
              <div className=''><b>{currentUser.full_name}</b></div>
              <div className=''>{currentUser.group_user_name}</div>
            </li>
          ) : null}
          <li><Link to="/dashboard" ><i className="material-icons">public</i> Home</Link></li>
          { currentUser && currentUser.affiliate === 'true' || currentUser && currentUser.affiliate === true ? (
            <li><Link to="/resellers" ><i className="material-icons">assignment_ind</i> Reff Reseller</Link></li>
          ) : null}
          <li><Link to="/products" ><i className="material-icons">content_paste_search</i> Shop</Link></li>
          <li><Link to="/whistlists" ><i className="material-icons">favorite</i> Whistlist</Link></li>
          <li><Link to="/orders" ><i className="material-icons">content_paste_go</i> Pesanan</Link></li>
          <li><Link to="/profile" ><i className="material-icons">account_circle</i> Profil</Link></li>
          <li><a href="#" onClick={() => {actLogout()}} ><i className="material-icons">power_settings_new</i> Keluar</a></li>
        </ul>
      </>
    ) : null
  )
}

Header.defaultProps = {
  title: 'Default'
}

export default Header
