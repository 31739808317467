import React, { useEffect, useState} from 'react';
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import BottomSheet from '../components/BottomSheet';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Orders = () => {
  const currentCompany = ReactSession.get("current_company");
  const params = new URLSearchParams(window.location.search);

  const token = ReactSession.get("token");
  const history = useHistory();

  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [statusShipping, setStatusShipping] = useState('');

  const [report, setReport] = useState({});
  const [orders, setOrders] = useState([]);
  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);

  const navCollection = [
    {'name':'Semua', 'value':''},
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dikemas', 'value':'packing'},
    {'name':'Dikirim', 'value':'shipped'},
    {'name':'Diterima', 'value':'received'},
    {'name':'Dibatalkan', 'value':'cancelled'},
    {'name':'Diretur', 'value':'retur'}
  ]

  const loadData = (keyword, status) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'order_items, product, booking_order, room', by_status_shipping: status} };

    API.get(`report_user`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      })

    API.get(`orders`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setOrders(data)
        setSpinnerScreen(false)
        setLoadMore(false);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { key: '', page: offset_page, include: 'order_items, product, booking_order, room', by_status_shipping: statusShipping } };
    
    API.get(`orders`, config)
      .then(res => {
        setLoadMore(false);
        const data = res.data.data;
        setOrders([...orders, ...data]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search, statusShipping);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData(search, statusShipping);
    setLoadMoreButton(false);
  }

  const statusTrackOrderSearch = (status) => {
    setStatusShipping(status)
    loadData(search, status);
  }

  const [stickyClass, setStickyClass] = useState('relative');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('nav-scroll') : setStickyClass('');
    }
  };

  useEffect(() => {
    if(token === undefined){
      history.push("/login")
    }

    setSearch(params.get('key'));
    loadData(params.get('key'), statusShipping);
    M.AutoInit();

    let elSide = document.querySelectorAll('.sidenav-overlay');
    let elBody = document.querySelectorAll('body');
    elSide.forEach((item: any) => {
      item.style.display = 'none';
    })
    elBody.forEach((item: any) => {
      item.style.overflow = 'auto';
    })

    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
    
  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed">
        <nav className={`nav-screen ${stickyClass}`} style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Pesanan Saya</span>
            <ul className="right">
              <li>
                <div className="input-field input-search-form">
                  <input id="search" autocomplete="off" type="search" value={search} onChange={e => updateSearch(e.target.value)}/>
                  <label className="label-icon" for="search"><i className="material-icons material-icon-search" style={{color: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color1 : '' ) }}>search</i></label>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <div className='back-grey'>
          <div className="row m-bottom0">
            <div className='col col s6 m6'>
              <div className='acumulation'>
                <div className='acumulation-total' style={{color: (currentCompany.company_color2)}}>{report.order_count ? numberFormat(report.order_count) : numberFormat(0)}</div>
                <small>Pesanan</small>
              </div>
            </div>
            <div className='col col s6 m6'>
              <div className='acumulation'>
                <div className='acumulation-total' style={{color: (currentCompany.company_color2)}}>{report.order_store ? currencyFormat(report.order_store) : numberFormat(0)}</div>
                <small>Nominal Transaksi</small>
              </div>
            </div>
          </div>

          { !(currentCompany.can_reservation || currentCompany.is_showroom) ? (
            <div className='tab-nav back-white'>
              <ul className='margin-none'>
                {navCollection.map((nav, index) => {
                  return (
                    <li key={index+=1} className={nav.value === statusShipping ? 'active' : null } onClick={() => { statusTrackOrderSearch(nav.value)} }>
                      {nav.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null
          }
          
          {
            orders && orders.length > 0 ? (
              orders.map((order) => {
                return (
                  <div className="row" style={{padding: 10, marginBottom: 0}}>
                    <div className="box-order-new">
                      <div className="card horizontal card-cart">
                        <b>
                          <Link to={'orders/'+order.id} >
                            #{order.code ? order.code.toUpperCase() : null}
                            {
                              order.in_outlet === false ? (
                                <>
                                  &nbsp;{order.dropship === true ? '(Dropship)' : ''}
                                </>
                              ) : null
                            }
                          </Link>
                          { !(currentCompany.can_reservation || currentCompany.is_showroom) ? (
                            <label className='label-basic' style={styles.status(order.status_shipping)}>{order.status_shipping_txt}</label>
                            ) : null 
                          }
                        </b>
                        <label className='btn-right-label2'>
                          {order.order_date}
                        </label>
                      </div>
    
                      { order.order_items ? (
                        order.order_items.map((oi) => {
                          return (
                            <>
                              <div key={oi.id} className="card horizontal card-cart border-none p-bottom-0">
                                <div className="card-image">
                                  <img src={oi.product.image_url}/>
                                </div>
                                <div className="card-stacked">
                                  <div className="card-content padding-top0">    
                                    <div className='col col s8 m8 padding0'>
                                      <div className='card-product'>
                                        <Link to={'product/'+oi.product_id} >{oi.product_name}</Link>
                                      </div>
                                      { oi.variant_name_1 ? (
                                        <div className='card-variant'>
                                          <label className='label label-default'>Varian {oi.variant_name_1} {oi.variant_name_2}</label>
                                        </div>
                                      ) : null}
                                    </div>
                                    
                                    <div className='col col s4 m4 padding0'>
                                      <div className='card-variant text-right'><span className=''>{oi.quantity} x {currencyFormat(oi.store_price)}</span></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })
                      ) : null }

                      { order.booking_order.id ? (
                        <div className="card horizontal card-cart border-none p-bottom-0">
                          <div className="card-image">
                            <img src={order?.booking_order?.room?.image_url}/>
                          </div>
                          <div className="card-stacked card-booking">
                            <div className="card-content padding-top0">

                              <div className='col col s8 m8 padding0'>
                                <div className='card-booking-title'>
                                  <Link to={'rooms/'+order?.booking_order?.room?.id} >Reservasi {order?.booking_order?.room?.name}</Link>
                                </div>
                                {
                                  currentCompany.is_hotel ? (
                                    <div className='card-variant'>
                                      <span>{order?.booking_order?.start_date} s/d {order?.booking_order?.end_date} </span>
                                      <br/>
                                      <span>{order?.booking_order?.booking_days} Hari, {order?.booking_order?.booking_people} Orang</span>
                                    </div>
                                  ) : (
                                    <div className='card-variant'>
                                      <span>{order?.booking_order?.start_date} {order?.booking_order?.booking_time}</span>
                                      <br/>
                                      <span>{order?.booking_order?.booking_people} Orang</span>
                                    </div>
                                  )
                                }
                              </div>
                              
                              <div className='col col s4 m4 padding0'>
                                <div className='card-variant text-right'><span className=''>{currencyFormat(order?.booking_order?.booking_price)}</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null }

                      {
                        order.in_outlet === false && order.shipping_price > 0 ? (
                          <div className="card horizontal card-cart border-none p-bottom-0">
                            <div className='col col s6 m6 padding0'>
                              <span className='span-grey'>{order.expedition_name.toUpperCase()} {order.expedition_service} {order.expedition_number ? order.expedition_number : ''}</span>
                            </div>
                            <div className='col col s6 m6 padding0 text-right'>
                              <span className='span-grey'>Ongkir</span> <span className=''>{currencyFormat( (parseInt(order.shipping_price)+parseInt(order.fee_cod)) )}</span>
                            </div>
                          </div>
                        ) : null
                      }

                      <div className="card horizontal card-cart border-none">
                        <div className='col col s6 m6 padding0'>
                          <span className='span-grey' style={styles.status_payment(order.status_payment)}>{order.status_payment_txt.toUpperCase()}</span>
                        </div>
                        <div className='col col s6 m6 padding0 text-right'>
                          <span className='span-grey'>Total Dibayar</span> <b className=''>{currencyFormat(parseInt(order.store_price)+(parseInt(order.shipping_price)+parseInt(order.fee_cod)+parseInt(order.uniq_code))-parseInt(order.subsidi) )}</b>
                        </div>
                      </div>

                      {
                        order.dropship === true ? (
                          <>
                            <div className="card horizontal card-cart border-none p-top-0">
                              <div className='col col s6 m6 padding0'>
                                Pembayaran {order.customer_payment_method.toUpperCase()}
                              </div>
                              <div className='col col s6 m6 padding0 text-right'>
                                <span className='span-grey'>Total Tagihan Konsumen</span> <b className=''>{currencyFormat(parseInt(order.sell_price)+(parseInt(order.shipping_price)+parseInt(order.fee_cod)) )}</b>
                              </div>
                            </div>
                          </>
                        ) : null
                      } 
                    </div>
                  </div>
                )
              })
            ) : (
              <div className='col s12 m12'>
                <div className='blank-message' style={{padding: 20}}>
                  <i class="material-icons dp48" style={{fontSize:50, marginBottom: 10}}>filter_none</i>
                  <br/>
                  Pesanan Tidak Ditemukan
                </div>
              </div>
            )
          }

          {paging ? (
            <div className="row m-bottom0 back-white">
              <div className='col s12 m12'>
                <div onClick={loadMoreData} className='load-more-btn'>
                  Load More
                  {loadMore ? (
                    <center><Dots color='#444' /></center>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <BottomSheet/>
    </>
    ) : null
  );
}

export default Orders;

const styles: StyleSheet = {
  status: (status) => ({
    color: status === 'pending' ? 'orange' : (status === 'shipped' || status === 'received') ? 'green' : status === 'packing' ? '#11cdef' : 'red' ,
    background: 'white', fontSize: 10
  }),
  status_payment: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}
