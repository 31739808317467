import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';

const Login = () => {
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");

  const [domain, setDomain] = useState('');
  const [company, setCompany] = useState({});
  const [identifier, setIdentifier] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [spinner, setSpinner] = useState(false);

  // Function to toggle the password visibility state
  const toggleShowPassword = () => {
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  };

  const loadData = () => {
    // let hostName = 'gadgetstore.id';
    // let hostName = window.location.hostname.split('.').slice(1).join('.')
    let hostName = window.location.hostname;
    setDomain(hostName);

    const config = { params: {domain: hostName} };
    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
      })
  }

  const actLogin = () => {
    setSpinner(true);
    API.post('sessions', {identifier: identifier, password: password, domain: domain, include: 'company, websetting_galleries, last_billing, last_package'} )
      .then(res => {
        setSpinner(false);
        ReactSession.set("token", res.data.meta.token);
        ReactSession.set("current_user", res.data.data);
        ReactSession.set("current_company", res.data.data.company);
        ReactSession.remove("current_order_count");
        ReactSession.remove("current_order_id");
        history.push("/dashboard");
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Login Gagal, Terjadi kesalahan di server')
        }else{
          if(error.response.data.meta.status.message !== 'NOT ENOUGH OR TOO MANY SEGMENTS'){
            alert.show(error.response.data.meta.status.message);
          }
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    loadData('');

    // if(token !== undefined && currentUser){
    //   history.push("/dashboard")
    // }
  },[])

  return (
    <>
      { company ? (
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.company_icon_url} />
          <link rel="icon" href={company.company_icon_url} />
        </Helmet>
      ) : null}

      { company ? (
        <>
          <HeaderScreen title="Log In"/>
          <div className="row login-form">
            <div className="input-field col s12">
              { company ? (
                <a href='/'>
                  <img src={company.company_icon_url} className="login-logo"/>
                </a>
              ) : (
                <h1 className='h1-login'>Login</h1>
              ) }
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>account_circle</i>
              <input placeholder='Email or No.Hp' type="text" className="validate" value={identifier} onChange={e => setIdentifier(e.target.value)} />
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>lock</i>
              <input placeholder='Kata Sandi' type={passwordType} className="validate" value={password} onChange={e => setPassword(e.target.value)} />
              <i className="material-icons span-blue prefix eye" onClick={toggleShowPassword} style={{color: passwordType === 'password' ? '#ccc' : '#000' }}>
                remove_red_eye
              </i>
            </div>
            <div className="box-col">
              { spinner ? (
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" >
                  <center><Dots color='#fff' /></center>
                </button>
              ) : (
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" onClick={actLogin} >
                  Masuk
                </button>
              ) }
            </div>
            <div className="col s12">
              <center>
                <span>Anda Belum Punya Akun ? <Link to="/register" >Daftar Sekarang</Link></span>
                <br/>
                <span>Lupa Kata Sandi ? <Link to="/forgot_password" >Pulihkan Kata Sandi</Link></span>
              </center>
            </div>
          </div>
        </>
      ) : (
        <ScreenLoading/>
      )}
    </>
  )
}

export default Login