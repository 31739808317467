import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css'
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { DatePicker, TimePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function createMarkup(desc) {
  return {__html: desc.split('\n').map(str => <p>{str}</p>)};
}

function NewlineText(props) {
  const text = props.text;
  return text.split('\n').map(str => <p>{str}</p>);
}

const RoomShow = () => {
  const {id} = useParams();

  const currentCompany = ReactSession.get("current_company");
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const current_order_id = ReactSession.get("current_order_id");
  const current_order_count = ReactSession.get("current_order_count");
  const [showBoxCart, setShowBoxCart] = useState(false);
  const [readyOrder, setReadyOrder] = useState(false);
  const [favorited, setFavorited] = useState(false);

  const [company, setCompany] = useState({});
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [startDate, setStartDate] = useState();
  const [bookingPeople, setBookingPeople] = useState(1);
  const [bookingNote, setBookingNote] = useState('');
  const [bookingTime, setBookingTime] = useState('');
  const [bookingDuration, setBookingDuration] = useState('');

  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [errorMessages, setErrorMessages] = useState();

  const IncrementItem = () => {
    setQuantity(quantity+1);
  }

  const DecreaseItem = () => {
    let qty = quantity-1
    if(qty <= 1){
      setQuantity(1);
    }else{
      setQuantity(quantity-1);
    }
  }

  const IncrementItemPeople = () => {
    setBookingPeople(bookingPeople+1);
  }

  const DecreaseItemPeople = () => {
    let qty = bookingPeople-1
    if(qty <= 1){
      setBookingPeople(1);
    }else{
      setBookingPeople(bookingPeople-1);
    }
  }

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: currentCompany.id, include: 'company,outlet'} };

    API.get(`rooms/${id}`, config)
      .then(res => {
        setSpinnerScreen(false);
        const data = res.data.data;
        setProduct(data);
        setQuantity(1);
        setBookingPeople(1);

        M.AutoInit();
        var elem = document.querySelector(".carousel");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {include: ''} };
    API.get(`user_company`, config2)
      .then(res => {
        const com = res.data.data;
        setCompany(com);
      })
  }

  const addCart = () => {
    setSpinner(true);
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      room_id: id, 
      order_id: current_order_id,
      start_date: startDate,
      booking_people: bookingPeople,
      booking_days: quantity,
      booking_note: bookingNote,
      booking_time: bookingTime,
      booking_duration: bookingDuration,
      include: 'count_shops'
    };

    API.post('order_items', paramsData, config)
      .then(res => {
        setSpinner(false);
        alert.success('Berhasil di masukan ke pesanan anda')
        const data = res.data.data;
        ReactSession.set("current_order_id", data.order_id);
        ReactSession.set("current_order_count", data.count_shops);
        setShowBoxCart(false);
        setReadyOrder(true);
      }).catch(error => {
        setSpinner(false);
        if(error === undefined){
          setErrorMessages('Gagal, Telah terjadi kesalahan server');
        }else{
          setErrorMessages(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const onShowCart = () => {
    setShowBoxCart(true);
  }

  const onHideCart = () => {
    setShowBoxCart(false);
  }

  const [stickyClass, setStickyClass] = useState('relative');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('nav-scroll') : setStickyClass('');
    }
  };

  const handleChangeTime = (hours) => {
    const milliseconds = (hours * 60 * 60 * 1000);
    const milliseconds_7 = (5 * 60 * 60 * 1000);
    const date = new Date(milliseconds + milliseconds_7);
    const time = format(date, "hh:mma");
    setBookingTime(time);
  };
  
  useEffect(() => {
    loadData();

    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
    
  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className={`nav-screen ${stickyClass}`} style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/rooms" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">{product?.name}</span>
            <ul className="right">
              <li>
                <Link to="/carts">
                  <i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>shopping_basket</i>
                  { current_order_count ? ( <span className='icon-shop-count'>{current_order_count}</span> ) : null }
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div className="row m-bottom-0 back-grey">
            <div className="carousel carousel-slider carousel-product">
              {product.stock < 1 ? (<div className='outstock-label'>Tidak Tersedia</div>) : null}
              <div className="carousel-item active"><img src={product.image_url}/></div>
            </div>
          </div>
          
          <div className="row" style={{marginBottom:0}}>
            <div className="col s12 m12 p-bottom-0">
              <div>
                <small>{product.category_name ? product.category_name : ''} {product.sub_category_name ? product.sub_category_name : ''} {product.brand_name ? product.brand_name : ''}</small>
              </div>
              <div className='title-card-show'>{product.name}</div>
            </div>

            <div className='col s9 m9 p-bottom-0'>
              <div className='title-card-price' style={{color: (currentCompany ? currentCompany.company_color2 : '')}}>
                {
                  (product.end_price > 0 && product.end_price < product.start_price) ? (
                    <>
                      <span className='discount-price'>{currencyFormat(product.start_price)}</span>&nbsp;
                      {currencyFormat(parseInt(product.end_price))}
                    </>
                  ) : (
                    <span>{parseInt(product.start_price) === 0 ? "FREE" : currencyFormat(product.start_price)}</span>
                  )
                }
              </div>
            </div>
            <div className='col s3'>
              <div className='available-room'>
                Tersedia <br/><b>{product.stock}</b>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12 m12">
              <div className='title-card-desc'>
                <div className='category-card category-fasilities-show' style={{marginBottom:10}}>
                  <i class="material-icons left">apps</i> Dilengkapi Fasilitas :
                  <hr className='border-facilities' style={{marginBottom: 10}}/>
                  {
                    product.facilities && product.facilities.length > 0 ? (
                      <>
                        {
                          product.facilities.map((f) => {
                            return (
                              <label className='label'>{f.value}</label>
                            )
                          })
                        }
                      </>
                    ) : null
                  }
                </div>

                <div className="category-card category-fasilities" style={{marginBottom:10}}>
                  { product && product?.outlet?.id ? (
                    <div className='title-card-val'>
                      <i class="material-icons left">location_on</i> {product?.outlet?.district}, {product?.outlet?.city}, {product?.outlet?.province}
                    </div>
                  ) : product.company ? (
                    <div className='title-card-val'>
                      <i class="material-icons left">location_on</i> {product?.company?.district}, {product?.company?.city}, {product?.company?.province}
                    </div>
                  ) : null }
                  <hr className='border-facilities'/>
                </div>
                
                <div className="category-card category-fasilities" style={{marginBottom:10}}>
                  { product && product.description ? (
                    <NewlineText text={product.description} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          { showBoxCart ? (
            <div className="row widget-box-chart">
              <div className="modal-widget-box-chart">
                <div className='closeBtn' onClick={onHideCart}> X</div>
                <div className="col s12 m12">
                  <div className="card horizontal">
                    <div className="card-image">
                      <img src={product.image_url}/>
                    </div>
                    <div className="card-stacked">
                      <div className="card-content padding-top0">
                        <p>{product.name}</p>
                        <b className='card-variant' style={{marginBottom: 0}}>
                          {
                            (product?.end_price > 0 && product?.end_price < product?.start_price) ? (
                              <>
                                <span className='discount-price'>{currencyFormat(product.start_price)}</span>&nbsp;
                                {currencyFormat(parseInt(product.end_price))}
                              </>
                            ) : (
                              <span>{parseInt(product.start_price) === 0 ? "FREE" : currencyFormat(product.start_price)}</span>
                            )
                          }
                        </b>
                        <div>Tersedia <b>{product.stock}</b></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col s5 m5">
                  <div className='title-card-label m-top-10'>Tanggal Booking</div>
                </div>
                <div className="col s7 m7">
                  <div className="input-new padding-0 input-new-date">
                    <DatePicker className='input-radius' placeholder='Pilih Tanggal' value={startDate} onChange={e => setStartDate( format(e, "yyyy-MM-dd") )}/>
                  </div>
                </div>

                {
                  currentCompany.company_app_type === 'reservation' ? (
                    <>
                      <div className="col s5 m5">
                        <div className='title-card-label m-top-10'>Jam Booking</div>
                      </div>
                      <div className="col s7 m7">
                        <div className="input-new padding-0 input-new-date">
                          <TimePicker
                            placeholder='Pilih Jam'
                            onChange={handleChangeTime}
                            value={bookingTime}
                          />
                        </div>
                      </div>
                      </>
                  ) : null
                }

                {
                  currentCompany.is_hotel ? (
                    <>
                      <div className="col s5 m5 p-bottom0">
                        <div className='title-card-label m-top-10'>Jumlah Hari</div>
                      </div>
                      <div className="col s7 m7 p-bottom0">
                        <div className='input-group'>
                          <button className='btn btn-default no-radius' onClick={IncrementItem}>+</button>
                          <input placeholder="Jumlah" type="number" min='1' className="validate input-border" value={quantity} onChange={e => setQuantity(e.target.value)} />
                          <button className='btn btn-default no-radius' onClick={DecreaseItem}>-</button>
                        </div>
                      </div>
                    </>
                  ) : null
                }

                <div className="col s5 m5 p-bottom0">
                  <div className='title-card-label m-top-10'>Jumlah Orang</div>
                </div>
                <div className="col s7 m7 p-bottom0">
                  <div className='input-group'>
                    <button className='btn btn-default no-radius' onClick={IncrementItemPeople}>+</button>
                    <input placeholder="Jumlah" type="number" min='1' className="validate input-border" value={bookingPeople} onChange={e => setBookingPeople(e.target.value)} />
                    <button className='btn btn-default no-radius' onClick={DecreaseItemPeople}>-</button>
                  </div>
                </div>

                <div className="col s12 m12 p-bottom0">
                  <input placeholder="Tambah Catatan" type="text" className="validate input-border field-note-booking" value={bookingNote} onChange={e => setBookingNote(e.target.value)} />
                </div>

                <div className="col s12 m12">
                  { errorMessages ? (
                    <div className="error-card">
                      <span>{errorMessages}</span>
                    </div>
                  ) : null}
                  { spinner ? (
                    <button className='btn btn-chart onBtnCart' style={{background: (currentCompany.company_color3)}}><i className="material-icons left">control_point</i> <center><Dots color='#fff' /></center></button>
                  ) : (
                    <button className='btn btn-chart onBtnCart' style={{background: (currentCompany.company_color3)}} onClick={addCart}>
                      <i className="material-icons left">control_point</i> Reservasi Sekarang
                    </button>
                  ) }
                </div>
              </div>
            </div>
          ) : null }

          <div className='add-chart-seaction'>
            <div className="row add-chart-seaction-box">
              <div className="col s5 m5">
                {/* <a href={"whatsapp://send?text=hello&phone" +company.phone1} className='btn btn-chat' style={{background: (currentCompany.company_color2)}}>Go to Whatsapp</a> */}
                <a href={'https://api.whatsapp.com/send?phone='+currentCompany.phone1+'&text=Saya%20mau%20bertanya%20produk%20'+product.title} className='btn btn-chat'><i className="material-icons left">phone_forwarded</i> WhatsApp</a>
              </div>
              <div className="col s7 m7">
                {
                  currentUser ? (
                    <>
                      {
                        readyOrder ? (
                          <Link to="/carts">
                            <button className='btn btn-chart' style={{background: (currentCompany.company_color3)}} onClick={onShowCart}><i className="material-icons left">control_point</i> Bayar Sekarang</button>
                          </Link>
                        ) : (
                          <button className='btn btn-chart' style={{background: (currentCompany.company_color3)}} onClick={onShowCart}>
                            <i className="material-icons left">control_point</i> Reservasi Sekarang
                          </button>
                        )
                      }
                    </>
                  ) : (
                    <Link to="/login">
                      <button className='btn btn-chart' style={{background: (currentCompany.company_color3)}} onClick={onShowCart}>
                        <i className="material-icons left">control_point</i> Reservasi Sekarang
                      </button>
                    </Link>
                  )
                }
              </div>
            </div>
          </div>
        </>
      )}
    </>
    ) : null
  );
}

export default RoomShow;
