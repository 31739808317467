import React, { useEffect, useState} from 'react';
import { useAlert } from 'react-alert'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import BottomSheet from '../components/BottomSheet';
import Countdown from 'react-countdown';
import LinesEllipsis from 'react-lines-ellipsis';
import ScreenLoading from '../components/ScreenLoading';
import OutletSection from '../components/OutletSection';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Rooms = () => {
  const currentCompany = ReactSession.get("current_company");
  const alert = useAlert();
  const params = new URLSearchParams(window.location.search);
  const current_order_count = ReactSession.get("current_order_count");

  const token = ReactSession.get("token");
  const history = useHistory();

  const [all, setAll] = useState(true);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
 
  const [galleries, setGalleries] = useState([]);
  const [galleriesPromo, setGalleriesPromo] = useState([]);

  const [currentUser, setCurrentUser] = useState();
  const [products, setProducts] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);

  const loadData = (keyword) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'company, websetting_galleries, last_billing, last_package, outlet'}};

    API.get(`sessions`, config )
      .then(res => {
        const data = res.data.data;
        setCurrentUser(data);

        if(data.company){
          ReactSession.set("current_company", data.company);

          if(data.company.websetting_galleries){
            setGalleries(data.company.websetting_galleries.filter((g) => g.category === 'home_app'));
            setGalleriesPromo(data.company.websetting_galleries.filter((g) => g.category === 'home_promo'));
          }

          var elem = document.querySelector(".slider2");
          var instance = window.M.Carousel.init(elem, {
            fullWidth: true,
            indicators: true,
          });
        }
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }
        console.log(error.response);
      })
    
    const config_product = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: currentCompany.id, key: keyword, by_position: 'updatest', include: '', by_status: true} };
    API.get(`rooms`, config_product)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setProducts(data)
        setSpinner(false)
        setLoadMore(false);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const [stickyClass, setStickyClass] = useState('relative');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('nav-scroll') : setStickyClass('');
    }
  };
  
  useEffect(() => {
    setSearch(params.get('key'));
    loadData(params.get('key')); 
  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className={`nav-screen ${stickyClass}`} style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left" style={{width: '95%'}}>
              <li style={{width: '100%'}}>
                <div className="input-field input-search-form full-width">
                  <input id="search" autocomplete="off" type="search" value={search} onChange={e => updateSearch(e.target.value)}/>
                  <label className="label-icon" for="search"><i className="material-icons material-icon-search" style={{color: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color1 : '' ) }}>search</i></label>
                </div>
              </li>
            </ul>
            {/* <ul className="right">
              {
                currentUser ? (
                  <li>
                    <Link to="/carts">
                      <i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>shopping_basket</i>
                      { current_order_count ? ( <span className='icon-shop-count'>{current_order_count}</span> ) : null }
                    </Link>
                  </li>
                ) : null 
              }
            </ul> */}
          </div>
        </nav>
      </div>

      {
        currentUser ? (
          <OutletSection token={token} currentUser={currentUser} currentCompany={currentCompany}/>
        ) : null
      }
      
       <div className="row p-top-10" style={{margin:0}}>
        { spinner ? (
            <div className="col s12 m12" >
              <center><Dots color='#444' /></center>
            </div>
          ) : (
            <>
              { products && products.length > 0 ? (
                <>
                  {
                    products.map((p) => {
                      let price = p.start_price;
                      let discount = p.end_price;

                      return (
                        <div className="col s12 m12" key={p.id} style={{paddingTop: 0}}>
                          <Link to={'/room/'+p.id} className="card card-product vertical">
                            <div class='row row-car'>
                              <div className="col s5 div-car">
                                <div className="card-image car-image">
                                  {p.stock < 1 ? (<div className='outstock-label'>Tidak Tersedia</div>) : null}
                                  <img src={p.image_url}/>
                                </div>
                              </div>
                              <div className="col s7" style={{paddingRight: 0}}>
                                <div className="card-content2">
                                  <div className='title-card-2' style={{fontWeight: 500}}>
                                    <LinesEllipsis
                                      text={p.name}
                                      maxLine='2'
                                      ellipsis='...'
                                      trimRight
                                      basedOn='letters'
                                    />  
                                  </div>
                                  <div className='card-description'>
                                    <LinesEllipsis
                                      text={p.description}
                                      maxLine='2'
                                      ellipsis='...'
                                      trimRight
                                      basedOn='letters'
                                    />  
                                  </div>
                                  <div className='price-card' style={{color: (currentCompany ? currentCompany.company_color2 : '')}}>
                                    {
                                      (discount > 0 && discount < price) ? (
                                        <>
                                          <span className='discount-price'>{numberFormat(price)}</span>&nbsp;
                                          {numberFormat(parseInt(discount))}
                                        </>
                                      ) : (
                                        <span>{parseInt(price) === 0 ? "FREE" : numberFormat(price)}</span>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    })
                  }
                </>
              ) : (
                <div className='col s12 m12'>
                  <div className='blank-message' style={{padding: 20}}>
                    <i class="material-icons dp48" style={{fontSize:50, marginBottom: 10}}>filter_none</i>
                    <br/>
                    Data Tidak Ditemukan
                  </div>
                </div>
              )}
            </>
          )
        }
      </div>
      
      <BottomSheet/>
    </>
    ) : (
      <ScreenLoading/>
    )
  );
}

export default Rooms;
