import React, { useEffect, useState } from 'react';
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import ReactSession from '../ReactSession';

const HeaderScreen = ({title, link}) => {
  const currentCompany = ReactSession.get("current_company");
  const history = useHistory();

  const [stickyClass, setStickyClass] = useState('relative');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('nav-scroll') : setStickyClass('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
    
  },[])

  return (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className={`nav-screen ${stickyClass}`} style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to={link} ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">{title}</span>
          </div>
        </nav>
      </div>
    </>
  )
}

HeaderScreen.defaultProps = {
  title: 'Default',
  link: '/dashboard'
}

export default HeaderScreen
