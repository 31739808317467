import React, { useEffect, useState } from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import RoomSection from '../components/RoomSection';
import BottomSheet from '../components/BottomSheet';
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import API from '../components/utils/api';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useAlert } from 'react-alert';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet';
import ScreenLoading from '../components/ScreenLoading';
import OutletSection from '../components/OutletSection';
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";

import {
  ShopIcon, 
  MoneyIcon, 
  PointIcon, 
  AffiliateIcon,
  BlogIcon,
  CatalogIcon,
  CustomerIcon,
  InvoiceIcon,
  ReportIcon,
  ToolsIcon,
  ConfirmationIcon,
  ProspectIcon,
  DownloadIcon,
  SettingIcon,
  PresIcon
} from '../assets'

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function Dashboard() {
  const alertPlug = useAlert();
  const [company, setCompany] = useState();
  const token = ReactSession.get("token");
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();
  const [paymentNumber, setPaymentNumber] = useState('');
  const [virtualMoney, setVirtualMoney] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [mitra, setMitra] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const [galleriesPromo, setGalleriesPromo] = useState([]);
  const [promo, setPromo] = useState({});
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const [bookingPeople, setBookingPeople] = useState(1);
  const [bookingName, setBookingName] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'company, websetting_galleries, staff, last_billing, last_package, outlet'}};

    API.get(`sessions`, config, {} )
      .then(res => {
        const data = res.data.data;
        setCurrentUser(data);
        setVirtualMoney(parseInt(data.virtual_money));
        setPaymentNumber(data.payment_bank_name+'-'+data.payment_bank_number);
        if(data.company){
          ReactSession.set("current_company", data.company);
          setCompany(data.company);
          loadLastPromo(data.company.id);
          setBookingName(data.full_name)

          if(data.company.websetting_galleries){
            setGalleries(data.company.websetting_galleries.filter((g) => g.category === 'home_app'));
            setGalleriesPromo(data.company.websetting_galleries.filter((g) => g.category === 'home_promo'));
          }

          loadCategories(data.company.id);
        }
        if(data.group_user_id > 0){
          setMitra(true)
        }

        var elem = document.querySelector(".slider1");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });

        var elem = document.querySelector(".slider2");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });
      })
      .catch(error => {
        setCurrentUser();
        ReactSession.remove('token')
        ReactSession.remove('current_user')

        // let hostName = 'gadgetstore';
        // let hostName = window.location.hostname.split('.').slice(1).join('.')
        let hostName = window.location.hostname;
        // let hostName = 'gadgetstore.id';

        const config = { params: {domain: hostName, include: 'company, websetting_galleries, last_billing, last_package'} };
        API.get(`get_company`, config )
          .then(res => {
            const data = res.data.data;
            if(data){
              ReactSession.set("current_company", data);
              setCompany(data);
              loadLastPromo(data.id);
              if(data.websetting_galleries){
                setGalleries(data.websetting_galleries.filter((g) => g.category === 'home_app'));
                setGalleriesPromo(data.websetting_galleries.filter((g) => g.category === 'home_promo'));
              }

              loadCategories(data.id);
            }
    
            var elem = document.querySelector(".slider1");
            var instance = window.M.Carousel.init(elem, {
              fullWidth: true,
              indicators: true,
            });
    
            var elem = document.querySelector(".slider2");
            var instance = window.M.Carousel.init(elem, {
              fullWidth: true,
              indicators: true,
            });
          })

        // history.push("/");
        // if(error === undefined){
        //   alert.show('Gagal, Terjadi kesalahan di server')
        // }else{
        //   alert.show(error.response.data.meta.status.message);
        // }
        // console.log(error.response);
      })
  }

  const loadCategories = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true, active_promo: true } };
    API.get(`categories`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data)
      })

    API.get(`brands`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setBrands(data)
      })
  }

  const loadLastPromo = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true } };

    API.get(`last_promo`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPromo(data)
      })
  }

  const claimSaldo = () => {
    setSpinner(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const data = {
      category: 'out',
      virtual_money: virtualMoney,
      payment_number: paymentNumber
    }
    
    API.post(`claim_moneys`, data, config )
      .then(res => {
        const data = res.data.data;
        loadData();
        setSpinner(false);
        setOpenModal(false);
        alertPlug.success('Pengajual Berhasil, Tim Kami Akan Memproses Pengajuan Pencairan Dana Anda')
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }
  
  useEffect(() => {
    // if(token === undefined){
    //   history.push("/")
    // }
    loadData();
    M.AutoInit();
  },[])

  return (
    company ? (
      <>
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.company_icon_url} />
          <link rel="icon" href={company.company_icon_url} />
        </Helmet>

        { openModal && currentUser ? (
          <div id="modal1" className='modal modal-visible'>
            <div className='modal-header'>
              Ajukan Pencairan Saldo <i className="material-icons modal-close"  onClick={(e) => setOpenModal(false)}>close</i>
            </div>
            <div className='modal-content'>
              <div className="col s12 p-left-0 p-right-0">
                <label className='custom-label'>Masukan Nominal</label>
                <div className="input-field input-new box-input-field">
                  <input placeholder="Masukan Nominal" className="validate" value={virtualMoney} onChange={e => setVirtualMoney(e.target.value)}/>
                </div>
              </div>
              <div className="col s12 p-left-0 p-right-0">
                <div className="select-none-ui">
                  <label className='custom-label'>Transfer Ke Rekening</label>
                  <div className="input-field input-new">
                    <select className='select select-no-bdr' value={paymentNumber} onChange={e => setPaymentNumber(e.target.value)} >
                      <option value={currentUser.payment_bank_name+'-'+currentUser.payment_bank_number}>
                        {currentUser.payment_bank_name+'-'+currentUser.payment_bank_number}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col s12">
                { spinner ? (
                  <button className="btn waves-effect waves-light btn-large full-width m-top20" style={{background: (company.company_color3)}} type="submit" name="action" >
                    <center><Dots color='#fff' /></center>
                  </button>
                ) : (
                  <button className="btn waves-effect waves-light btn-large full-width m-top20" style={{background: (company.company_color3)}} type="submit" name="action" onClick={(e) => claimSaldo()} >
                    Ajukan Sekarang
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null}

        <Header title='Header Loop'/>

        {
          (company.last_package && (company.last_package.can_virtual_money == true || company.last_package.can_virtual_point == true) && (company.config_features['use_saldo'] || company.config_features['use_point_virtual']) ) && !company.cannot_virtual ? (
            <>
              { currentUser ? (
                <>
                  <>
                    { galleries && galleries.length > 0 ? (
                      <div className="row bg-grey m-bottom0">
                        <div className="slider1 carousel carousel-slider carousel-home carousel-home1">
                          {galleries.map((d, i) => 
                            <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="row bg-grey m-bottom0">
                        <div className="carousel-slider carousel-home blank-carousel carousel-home1" style={{background: (company.company_color2)}}>
                        </div>
                      </div>
                    ) }
                  </>
                  <div className="row bg-grey" style={{marginBottom:0}}>
                    <div className='col col s12 m12 bg-col-acumulation'>
                      <div className="wellcome-heading">
                        <div className="row">
                          <div className='col s10 m10'>
                            <h2>Hello, {currentUser.full_name.split(' ')[0]}</h2> 
                            {
                              (company.last_package.can_virtual_point == true) && (company.config_features && company.config_features['use_point_virtual']) && !company.cannot_virtual ? (
                              <div onClick={e => history.replace(`/history_points`)}>
                                 Poin&nbsp; 
                                 <b><i className="material-icons" style={{color: '#fff', fontSize: 12}}>animation</i>&nbsp;{currentUser.virtual_point ? numberFormat(currentUser.virtual_point) : numberFormat(0)}</b>
                              </div>
                              ) : null 
                            }
                          </div>
                          <div className='col s2 m2'>
                            <div className='avatar-dash-div' >
                              <img src={currentUser.avatar_url} className='avatar-dash'/>
                            </div>
                          </div>
                        </div>
                      </div>

                      {
                        (company.last_package.can_virtual_money == true) && (company.config_features && company.config_features['use_saldo']) && !company.cannot_virtual ? (
                          <div className='bg-acumulation-container'>
                            <div className="bg-acumulation-home">
                              <div className='col col s7 m7'>
                                <div className='acumulation-home pointer' onClick={e => history.replace(`/history_moneys`)}>
                                  <div className='acumulation-home-total' style={{textAlign: 'left'}}>
                                    <i className="material-icons" style={{color: (company.company_color1)}}>trending_up</i>
                                    <b>{currentUser.virtual_money ? currencyFormat(currentUser.virtual_money) : currencyFormat(0)}</b>
                                  </div>
                                </div>
                              </div>
                              <div className='col col s5 m5'>
                                <div className='acumulation-home pointer' onClick={(e) => setOpenModal(true)}>
                                  <div className='acumulation-home-total' style={{textAlign: 'right'}}>
                                    <div className='acumulation-input acumulation-input-top pointer' >
                                      <i className="material-icons" style={{color: (company.company_color1)}}>vertical_align_top</i> TARIK DANA
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null 
                      }
                    </div>
                  </div>
                </>
              ) : null }
            </>
          ) : (
            <>
              { currentUser ? (
                <div className="row bg-grey" style={{marginBottom: 0}}>
                  { galleries && galleries.length > 0 ? (
                    <div className="slider1 carousel carousel-slider carousel-home carousel-home1">
                      {galleries.map((d, i) => 
                        <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
                      )}
                    </div>
                  ) : (
                    <div className="carousel-slider carousel-home blank-carousel carousel-home1" style={{background: (company.company_color2)}}>
                    </div>
                  ) }
                  <div className='col col s12 m12 bg-col-acumulation' style={{paddingBottom: 0}}>
                    <div className="wellcome-heading" style={{top:-90}}>
                      <div className="row">
                        <div className='col s10 m10'>
                          <h2>Hello, {currentUser.full_name.split(' ')[0]}</h2> 
                        </div>
                        <div className='col s2 m2'>
                          <div className='avatar-dash-div' >
                            <img src={currentUser.avatar_url} className='avatar-dash'/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null }
            </>
          )
        }

        { galleriesPromo && galleriesPromo.length > 0 ? (
          <div className="row m-bottom0" style={{padding: 10, paddingBottom: 0}}>
            <div className="slider2 carousel carousel-slider carousel-home" 
                style={{
                        top: (
                          (currentUser &&
                          (company.last_package &&
                            (company.last_package.can_virtual_money == false && company.last_package.can_virtual_point == false)
                          )
                        ) ? -40 : (currentUser && currentUser.group_user_id === 0 &&
                          (company.last_package &&
                            (company.last_package.can_virtual_money == true && company.last_package.can_virtual_point == false) ||
                            (company.last_package.can_virtual_money == false && company.last_package.can_virtual_point == true)
                          )
                        ) ? -40 : 0)
                      }}>
              {galleriesPromo.map((d, i) =>
                <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
              )}
            </div>
          </div>
        ) : (
          <div className="row m-bottom0" style={{padding: 10, paddingBottom: 0}}>
            <div className="slider2 carousel carousel-slider carousel-home" style={{top: 0}}>
              <div className='carousel-item active' style={styles.banner_slide}>BANNER</div>
            </div>
          </div>
        ) }

        { mitra && currentUser ? (
          <div className="row" style={{marginTop: 20}}>
            <div className="col s12 m12 padding0">
              <div className="icon-apps" onClick={e => history.replace(`/reports`)}>
                <div className="icon-app">
                  <i class="material-icons dp48" style={{color: (company.company_color1)}}>insert_chart</i>
                </div>
                <div className='icon-app-name'>Report</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/payments`)}>
                <div className="icon-app">
                  <i class="material-icons dp48" style={{color: (company.company_color1)}}>exit_to_app</i>
                </div>
                <div className='icon-app-name'>Pembayaran</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/blogs`)}>
                <div className="icon-app">
                  <i class="material-icons dp48" style={{color: (company.company_color1)}}>ondemand_video</i>
                </div>
                <div className='icon-app-name'>
                  Blog
                </div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/content_kit`)}>
                <div className="icon-app">
                  <i class="material-icons dp48" style={{color: (company.company_color1)}}>photo_camera</i>
                </div>
                <div className='icon-app-name'>Konten Kit</div>
              </div>
            </div>
          </div>
        ) : null }

        {/* {
          (company.last_package.can_reservation == true) ? (
            <div className="row">
              <div className="col s12 m12">
                <div className='bg-acumulation-container'>
                  <div className="bg-reservation-home">
                    <div className="col s6">
                      <div className="input-field input-new col s12 margin-0">
                        <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>account_circle</i>
                        <input placeholder="Nama Pemesan" type="text" className="validate" value={bookingName} onChange={e => setBookingName(e.target.value)} />
                      </div>
                    </div>
                    <div className="col s6">
                      <div className="input-field input-new col s12 margin-0">
                        <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>people</i>
                        <input placeholder="Jumlah Orang" type="number" className="validate" value={bookingPeople} onChange={e => setBookingPeople(e.target.value)} />
                      </div>
                    </div>
                    <div className="col s6">
                      <div className="input-field input-new col s12 margin-0 padding-0">
                        <DatePicker className='input-radius' placeholder='Dari Tanggal' value={startDate} onChange={e => setStartDate( format(e, "yyyy-MM-dd") )}/>
                      </div>
                    </div>
                    <div className="col s6">
                      <div className="input-field input-new col s12 margin-0 padding-0">
                        <DatePicker className='input-radius' placeholder='Sampai Tanggal' value={endDate} onChange={e => setEndDate( format(e, "yyyy-MM-dd") )}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null 
        } */}

        {
          company.can_reservation ? (
            <RoomSection token={token} title='Reservasi Sekarang' currentUser={currentUser} currentCompany={company}/>
          ) : null
        }

        {
          (company.last_package && company.last_package.can_promo == true) ? (
            <>
              { company && promo && promo.id ? (
                <ProductSection token={token} title={promo.title} currentUser={currentUser} currentCompany={company} promo={promo} promo_id={promo.id}/>
              ) : null}
            </>
          ) : null
        }
        
        { company && company.domain.includes('gadget') ? (
          <>
            { brands && brands.length > 4 ? (
              <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
                <div className="col s12 m12" style={{padding: 0}}>
                  <div className='scroll-touch'>
                    <div className='scroll-container'>
    
                      {
                        brands.map((p) => {
                          return (
                            <Link to={'/products?brand_id='+p.id} className="icon-categories">
                              <div className="icon-image">
                                <img src={p.image_url}/>
                              </div>
                              <div className='icon-category-name'>{p.name}</div>
                            </Link>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            ) : null }
          </>
        ) : (
          <>
            { categories && categories.length > 4 ? (
              <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
                <div className="col s12 m12" style={{padding: 0}}>
                  <div className='scroll-touch'>
                    <div className='scroll-container'>

                      {
                        categories.map((p) => {
                          return (
                            <Link to={'/products?category='+p.id} className="icon-categories">
                              <div className="icon-image">
                                <img src={p.image_url}/>
                              </div>
                              <div className='icon-category-name'>{p.name}</div>
                            </Link>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            ) : null }

            { categories && categories.length === 0 ? (
              <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
                <div className="col s12 m12" style={{padding: 0}}>
                  <div className='scroll-touch'>
                    <div className='scroll-container'>
                      {
                        [1,2,3,4,5,6,8].map((p) => {
                          return (
                            <Link to={'#'} className="icon-categories">
                              <div className="icon-image" style={{background: '#ccc'}}>
                              </div>
                              <div className='icon-category-name'>Kategori</div>
                            </Link>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            ) : null }
          </>
        )}

        <OutletSection token={token} currentUser={currentUser} currentCompany={company} context='dashboard'/>

        <ProductSection token={token} title='Produk Promo' currentUser={currentUser} currentCompany={company}/>
        <ProductSection token={token} title='Produk Terlaris' currentUser={currentUser} currentCompany={company}/>
        <ProductSection token={token} title='Produk Terbaru' currentUser={currentUser} currentCompany={company}/>
        <BottomSheet/>
      </>
    ) : (
      <ScreenLoading/>
    )
  );
}

export default Dashboard;

const styles: StyleSheet = {
  banner_slide: ({
    padding: 70, textAlign: 'center', background: '#ccc', fontSize: 20
  })
}