import React, { useState, useEffect } from 'react'
import API from './utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import Countdown from 'react-countdown';
import ReactSession from '../ReactSession';
import LinesEllipsis from 'react-lines-ellipsis';

function currencyFormat(num) {
  return 'Rp.' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const RoomSection = ({token, title, currentUser, promo, promo_id, currentCompany}) => {
  const [products, setProducts] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const loadData = () => {
    let config = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: (currentCompany ? currentCompany.id : null), per_page: 30, by_position: 'latest', by_promo_id: promo_id, include: '', by_status: true} };

    API.get(`rooms`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setProducts(data)
        setSpinner(false)
      })
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="row margin-none">
      <div className="col s8 m8">
        <span className='title-card-content'>{title}</span>
      </div>
      <div className="col s4 m4 text-right">
        { promo && promo.end_date ? (
          <div className='countdown-txt'>
            <Countdown date={promo.end_date}/>
          </div>
        ) : (
          <Link className='title-view-more' to="/rooms" className="more-opts">Lihat Semua</Link>
        )}
      </div>

      { products && products.length > 0 ? (
        <>
          {
            products.map((p) => {
              let price = p.start_price;
              let discount = p.end_price;

              return (
                <div className="col s12 m12" key={p.id} style={{paddingTop: 0}}>
                  <Link to={'/room/'+p.id} className="card card-product vertical">
                    <div class='row row-car'>
                      <div className="col s5 div-car">
                        <div className="card-image car-image">
                          {p.stock < 1 ? (<div className='outstock-label'>Tidak Tersedia</div>) : null}
                          <img src={p.image_url}/>
                        </div>
                      </div>
                      <div className="col s7" style={{paddingRight: 0}}>
                        <div className="card-content2">
                          <div className='title-card-2' style={{fontWeight: 500}}>
                            <LinesEllipsis
                              text={p.name}
                              maxLine='2'
                              ellipsis='...'
                              trimRight
                              basedOn='letters'
                            />  
                          </div>
                          <div className='card-description'>
                            <LinesEllipsis
                              text={p.description}
                              maxLine='2'
                              ellipsis='...'
                              trimRight
                              basedOn='letters'
                            />  
                          </div>
                          <div className='price-card' style={{color: (currentCompany ? currentCompany.company_color2 : '')}}>
                            {
                              (discount > 0 && discount < price) ? (
                                <>
                                  <span className='discount-price'>{numberFormat(price)}</span>&nbsp;
                                  {numberFormat(parseInt(discount))}
                                </>
                              ) : (
                                <span>{parseInt(price) === 0 ? "FREE" : numberFormat(price)}</span>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })
          }
        </>
      ) : (
        <div className='col s12 m12'>
          <div className='blank-message' style={{padding: 20}}>
            <i class="material-icons dp48" style={{fontSize:50, marginBottom: 10}}>filter_none</i>
            <br/>
            Data Tidak Ditemukan
          </div>
        </div>
      )}
    </div>
  )
}

export default RoomSection
