import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';

const ForgotPassword = () => {
  const alert = useAlert();
  const history = useHistory();

  const [domain, setDomain] = useState('');
  const [company, setCompany] = useState({});
  const [identifier, setIdentifier] = useState('');
  const [pin, setPin] = useState('');
  const [openPin, setOpenPin] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const loadData = () => {
    // let hostName = 'onlinestore.web.id';
    // let hostName = window.location.hostname.split('.').slice(1).join('.')
    let hostName = window.location.hostname;
    setDomain(hostName);

    const config = { params: {domain: hostName, include: 'company, websetting_galleries, staff, last_billing, last_package'} };
    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
      })
  }

  const actSubmit = () => {
    setSpinner(true);
    API.post('forgot_password', {email: identifier, domain: domain} )
      .then(res => {
        setSpinner(false);
        setOpenPin(true);
        alert.success('Silahkan Cek Email Anda, Untuk Perubahan Kata Sandi Baru')
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Login Gagal, Terjadi kesalahan di server')
        }else{
          if(error && error.response){
            if(error.response.data.meta.status.message !== 'NOT ENOUGH OR TOO MANY SEGMENTS'){
              alert.show(error.response.data.meta.status.message);
            }
          }
        }
        console.log(error.response);
      })
  }

  const actSubmit2 = () => {
    setSpinner(true);
    API.post('change_forgot_password', {email: identifier, pin: pin, domain: domain} )
      .then(res => {
        setSpinner(false);
        alert.success('Perubahan kata sandi baru menggunakan kode pin berhasil, Anda sudah bisa login menggunakan kode pin baru tadi sebagai kata sandi baru akun anda!');
        history.push("/login");
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Login Gagal, Terjadi kesalahan di server')
        }else{
          if(error && error.response){
            alert.show(error.response.data.meta.status.message);
          }
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    loadData('');
  },[])

  return (
    <>
      { company ? (
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.company_icon_url} />
          <link rel="icon" href={company.company_icon_url} />
        </Helmet>
      ) : null}

      { company ? (
        <>
          <HeaderScreen title="Lupa Kata Sandi"/>
          <div className="row login-form">
            <div className="input-field col s12">
              { company ? (
                <a href='/'>
                  <img src={company.company_icon_url} className="login-logo"/>
                </a>
              ) : (
                <h1 className='h1-login'>Forgot Password</h1>
              ) }
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>account_circle</i>
              <input placeholder='Masukan Email Anda' type="text" className="validate" value={identifier} onChange={e => setIdentifier(e.target.value)} />
            </div>
            {
              openPin ? (
                <div className="input-field input-new col s12">
                  <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>lock</i>
                  <input placeholder='Kode Pin' type="text" className="validate" value={pin} onChange={e => setPin(e.target.value)} />
                </div>
              ) : null
            }

            {
              openPin ? (
                <div className="box-col">
                  { spinner ? (
                    <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" >
                      <center><Dots color='#fff' /></center>
                    </button>
                  ) : (
                    <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" onClick={actSubmit2} >
                      Submit
                    </button>
                  ) }
                </div>
              ) : (
                <div className="box-col">
                  { spinner ? (
                    <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" >
                      <center><Dots color='#fff' /></center>
                    </button>
                  ) : (
                    <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" onClick={actSubmit} >
                      Submit
                    </button>
                  ) }
                </div>
              )
            }

            <div className="col s12">
              <center>
                <span>Anda Belum Punya Akun ? <Link to="/register" >Daftar Sekarang</Link></span>
                <br/>
                <span>Anda Sudah Punya Akun ? <Link to="/" >Masuk Sekarang</Link></span>
              </center>
            </div>
          </div>
        </>
      ) : (
        <ScreenLoading/>
      )}
    </>
  )
}

export default ForgotPassword