import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Helmet } from 'react-helmet';
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';

const Register = () => {
  const alert = useAlert();
  const history = useHistory();

  const [company, setCompany] = useState({});
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [domain, setDomain] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);

  const [spinner, setSpinner] = useState(false);

  const loadData = () => {
    // let hostName = 'gadgetstore.id';
    // let hostName = window.location.hostname.split('.').slice(1).join('.')
    let hostName = window.location.hostname;
    setDomain(hostName);

    const config = { params: {domain: hostName, include: 'company, websetting_galleries, staff, last_billing, last_package'} };
    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
      })
  }

  const actPost = () => {
    ReactSession.remove("register_member");
    
    setSpinner(true);
    const data = {
      full_name: fullName, 
      username: username, 
      email: email, 
      phone1: phone, 
      gender: gender, 
      birthday: birthday, 
      address_code: addressCode, 
      address: address, 
      password: password,
      domain: domain,
      reff_code: referralCode
    }

    API.post('register', data )
      .then(res => {
        ReactSession.set("register_member", res.data.data);
        setSpinner(false);
        history.push("/welcome");
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Pendaftaran Gagal, Terjadi kesalahan di server')
        }else{
          if(error.response.data.meta.status.message !== 'NOT ENOUGH OR TOO MANY SEGMENTS'){
            alert.show(error.response.data.meta.status.message);
          }
        }
        console.log(error.response);
      })
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  // Function to toggle the password visibility state
  const toggleShowPassword = () => {
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  };

  useEffect(() => {
    loadData('');
  },[])

  return (
    <>
      { company ? (
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.company_icon_url} />
          <link rel="icon" href={company.company_icon_url} />
        </Helmet>
      ) : null}

      { company ? (
        <>
          <HeaderScreen title="Daftar"/>
          <div className="row login-form">
            <div className="input-field col s12">
              { company ? (
                <a href='/'>
                  <img src={company.company_icon_url} className="login-logo"/>
                </a>
              ) : (
                <h1 className='h1-login'>Buat Akun Baru</h1>
              ) }
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>account_circle</i>
              <input placeholder='Isi Nama Lengkap' type="text" className="validate" value={fullName} onChange={e => setFullName(e.target.value)} />
            </div>
            {/* <div className="col s6">
              <div className="m-bottom10">Username</div>
              <input placeholder='Username' type="text" className="validate input-back" value={username} onChange={e => setUsername(e.target.value)} />
            </div> */}
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>phone</i>
              <input placeholder='Isi No Hp/Whatsapp' type="text" className="validate" value={phone} onChange={e => setPhone(e.target.value)} />
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>email</i>
              <input placeholder='Isi Alamat Email' type="text" className="validate" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            {/* <div className="col s6 select-none-ui">
              <div className="m-bottom10">Jenis Kelamin</div>
              <select className="select" value={gender} onChange={e => setGender(e.target.value)} >
                <option value="">Pilih</option>
                <option value="Laki-Laki">Laki-Laki</option>
                <option value="Perempuan">Perempuan</option>
              </select>
            </div>
            <div className="col s6 datepicker-field">
              <div className="m-bottom10">Tanggal Lahir</div>
              <DatePicker options={{ yearRange: 70}} value={birthday} onChange={e => setBirthday( format(e, "yyyy-MM-dd") )}/>
            </div>
            <div className="col s12 autocomplete-field">
              <div className="m-bottom10">Kecamatan Domisili</div>
              <ReactSearchAutocomplete
                items={districts}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                formatResult={formatResult}
                />
            </div>
            <div className="col s12">
              <div className="m-bottom10">Alamat Lengkap</div>
              <input placeholder="Alamat Lengkap" type="text" className="input-back" value={address} onChange={e => setAddress(e.target.value)} />
            </div> */}
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>key</i>
              <input placeholder='Masukan Kata Sandi' type={passwordType} className="validate" value={password} onChange={e => setPassword(e.target.value)} />
              <i className="material-icons span-blue prefix eye" onClick={toggleShowPassword} style={{color: passwordType === 'password' ? '#ccc' : '#000' }}>
                remove_red_eye
              </i>
            </div>
            {
              (company.last_package && company.last_package.can_referral == true) ? (
                <div className="input-field input-new col s12">
                  <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>content_paste</i>
                  <input placeholder='Kode Referral (Optional)' type="text" className="validate" value={referralCode} onChange={e => setReferralCode(e.target.value)} />
                </div>
              ) : null
            }
            <div className="box-col">
              { spinner ? (
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" >
                  <center><Dots color='#fff' /></center>
                </button>
              ) : (
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (company ? company.company_color3 : '')}} type="submit" name="action" onClick={actPost} >
                  Daftar Sekarang
                </button>
              ) }
            </div>
            <div className="col s12">
              <center>
                <span>Anda Sudah Punya Akun ? <Link to="/login" >Masuk Sekarang</Link></span>
                <br/>
                <span>Lupa Kata Sandi ? <Link to="/forgot_password" >Pulihkan Kata Sandi</Link></span>
              </center>
            </div>
          </div>
        </>
      ) : (
        <ScreenLoading/>
      )}
    </>
  )
}

export default Register
