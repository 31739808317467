import React, { useEffect, useState} from 'react';
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import BottomSheet from '../components/BottomSheet';
import dateFormat from "dateformat";
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";

function numberFormat(num) {
  let nu = num ? num : 0
  return '' + parseFloat(nu).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Resellers = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [currentPage, setCurrentPage] = useState('daily_report');

  const [search, setSearch] = useState('');
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [report, setReport] = useState({});
  const [reportChartMember, setReportChartMember] = useState([]);

  const [offsetMember, setOffsetMember] = useState(1);
  const [pagingMember, setPagingMember] = useState(true);
  const [loadMoreMember, setLoadMoreMember] = useState(true);

  const [spinnerOrderMember, setSpinnerOrderMember] = useState(true);
 
  const loadData = (search, start_date, end_date) => {    
    loadChartMember(search, start_date, end_date);
  }

  const loadReport = (config) =>{
    API.get(`report_user`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      }).catch(error => {
        console.log(error.response);
      })
  }

  const updateSearch = (search) => {
    setOffsetMember(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    loadData(search, searchStartDate, searchEndDate);
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(search, start_date, searchEndDate);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(search, searchStartDate, end_date);
  }

  const loadChartMember = (search, start_date, end_date) =>{
    setOffsetMember(1);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: true, category_date: 'received_date', key_search: search, start_at: start_date, end_at: end_date, by_status_shipping: 'received' }};
    API.get(`referrals`, config)
      .then(res => {
        setSpinnerOrderMember(false);
        const data = res.data.report;
        setReportChartMember(data);
        setLoadMoreMember(false);
        if(res.data.total_pages <= 1){
          setPagingMember(false);
          console.log('No paging')
        }else{
          setPagingMember(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        setSpinnerOrderMember(false);
        setLoadMoreMember(false);
        setPagingMember(false);
        console.log(error.response);
      })
  }

  const loadChartMemberMore = () =>{
    setLoadMoreMember(true);
    setOffsetMember(offsetMember+1)
    let offset_page = offsetMember+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, pagination: true, category_date: 'received_date', key_search: search, start_at: searchStartDate, end_at: searchEndDate, by_status_shipping: 'received' }};
    API.get(`referrals`, config)
      .then(res => {
        setSpinnerOrderMember(false);
        const data = res.data.report;
        setLoadMoreMember(false);
        setReportChartMember([...reportChartMember, ...data]);
        if(res.data.total_pages === offset_page){
          setPagingMember(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const [stickyClass, setStickyClass] = useState('relative');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('nav-scroll') : setStickyClass('');
    }
  };

  useEffect(() => {  
    if(token === undefined){
      history.push("/login")
    }

    loadData(search, searchStartDate, searchEndDate);
    setSpinnerScreen(false);  

    let elSide = document.querySelectorAll('.sidenav-overlay');
    let elBody = document.querySelectorAll('body');
    elSide.forEach((item: any) => {
      item.style.display = 'none';
    })
    elBody.forEach((item: any) => {
      item.style.overflow = 'auto';
    })

    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };

  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed">
        <nav className={`nav-screen ${stickyClass}`} style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Reseller Saya</span>
            <ul className="right">
              <li>
                <div className="input-field input-search-form">
                  <input id="search" autocomplete="off" type="search" value={search} onChange={e => updateSearch(e.target.value)}/>
                  <label className="label-icon" for="search"><i className="material-icons material-icon-search" style={{color: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color1 : '' ) }}>search</i></label>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div class='row m-bottom0 m-top-10'>
            <div className="col s12">
              { spinnerOrderMember ? (
                <div className='text-center'>
                  <div className='loadLoading'><Dots color='#444' /></div>
                </div>
              ) : (
                <>
                  <div className='row row-initial m-bottom0'>
                    <div className="col s6 select-none-ui m-bottom0 m-top0 p-top0 datepicker-ipt">
                      <DatePicker className='input-radius' placeholder='Dari Tanggal' value={searchStartDate} onChange={e => updateSearchStartDate( format(e, "yyyy-MM-dd") )}/>
                    </div>
                    <div className="col s6 select-none-ui m-bottom0 m-top0 p-top0 datepicker-ipt">
                      <DatePicker className='input-radius' placeholder='Sampai Tanggal' value={searchEndDate} onChange={e => updateSearchEndDate( format(e, "yyyy-MM-dd") )}/>
                    </div>
                  </div>

                  { reportChartMember && reportChartMember.length > 0 ? (
                      <>
                        {
                          reportChartMember.map((u, i) => {
                            return (
                              <div className='list-content' key={u.id}>
                                <div class='row m-bottom0 row-netral'>
                                  <div className="col s2">
                                    <div className='acumulation-input'>
                                      <i className="material-icons" style={{color: (currentCompany.company_color1)}}>assignment_ind</i>
                                    </div>
                                  </div>
                                  <div className="col s7">
                                    <div style={{marginBottom: 5}}>
                                      <a href={'https://api.whatsapp.com/send?phone='+u.phone1+'&text=Hi%20'+u.full_name} className='label label-default btn-wa'>
                                        <b>{u.full_name}</b> {u.phone1}
                                      </a>
                                    </div>
                                    <span>Terdaftar {u.created_at}</span>
                                    {
                                      (u.city && u.province) ? (
                                        <>
                                          <br/>
                                          <span>{u.city}, {u.province}</span>
                                        </>
                                      ) : null
                                    }
                                    <br/>
                                    Order <b>{numberFormat(u.order_count)}</b> | Pembelian <b>{numberFormat(u.store_price)}</b>
                                    <br/>
                                    Omset <b>{numberFormat(u.sell_price)}</b>
                                  </div>
                                  <div className="col s3" style={{textAlign: 'right'}}>
                                    <b style={{fontSize: 12}}>{numberFormat(u.reff_commision)}</b>  
                                    <br/>
                                    <span>Komisi</span>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </>
                    ) : (
                      <div className='col s12 m12'>
                        <div className='blank-message' style={{padding: 20}}>
                          <i class="material-icons dp48" style={{fontSize:50, marginBottom: 10}}>filter_none</i>
                          <br/>
                          Data Tidak Ditemukan
                        </div>
                      </div>
                    )
                  }

                  {pagingMember ? (
                    <div onClick={loadChartMemberMore} class='load-more-btn-white'>
                      {loadMoreMember ? (
                        <center><Dots color='#444' /><br/></center>
                      ) : null}
                      SELANJUTNYA
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </>
      )}
      <BottomSheet/>
    </>
    ) : null
  );
}

export default Resellers;
