import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import ReactSession from '../ReactSession';

const ScreenLoading = () => {
  const currentCompany = ReactSession.get("current_company");
  return (
    <div className='screen-loading' style={{background: (currentCompany && currentCompany.company_color4 ? currentCompany.company_color4 : '#fff')}} >
      <div className="load-icon" >
        <center>
          {
            currentCompany && currentCompany.company_logo_url ? (
              <img src={currentCompany.company_logo_url} className="loader-logo"/>
            ) : null
          }
          <Spinner color='#fff' />
        </center>
      </div>
    </div>
  )
}

export default ScreenLoading